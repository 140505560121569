import React, { Suspense, lazy, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { getUserLocalStorage, redirectTo } from "util/util";
import { ROLES } from "constants/roles";
import { withCookies } from "HOC/withCookies/withCookies";
import { clearTags } from "actions/searchBy";
import { PATH } from "constants/path";
import ScrollToTop from "components/ScrollToTop";
import { getFilesRoot } from "actions/files";
import { SocketContainer } from "containers/SocketContainer";
import createHistory from "history/createBrowserHistory";
// import ReactGA from 'react-ga';
import { isUUID } from "util/util";

import { SkeletonOrigin } from "../components/skeleton/Origin";
import { gtag } from "ga-gtag";
import ui from "react-redux-ui-tools";
const history = createHistory();
const historyListener = (location) => {
  const page = location.pathname + location.search + location.hash;
  if (
    !(page === "/#/" || page === "/#" || page === "#/" || page === "#") &&
    window.dataLayer
  ) {
    gtag("event", "page_view", {
      page: page.includes("~") ? page.split("~")[0] : page,
    });
    gtag("set", "page", page.includes("~") ? page.split("~")[0] : page);
    // ReactGA.set({ page });
    // ReactGA.pageview(page);
  }
};
history.listen(historyListener);
historyListener(window.location);

const AdminRoutes = lazy(() =>
  import(/* webpackChunkName: "AdminRoute" */ "views/adminRoutes")
);
const BusinessRoutes = lazy(() =>
  import(/* webpackChunkName: "BusinessRoute" */ "views/businessRoutes")
);

const _Routes = (props) => {
  const prevUIRef = React.useRef(null);
  // const [doUpdate, setDoUpdate] = useState();
  useEffect(() => {
    if (prevUIRef.current) {
      if (
        (prevUIRef.current
          .split("/")
          .some((i) => ["reports", "news"].includes(i)) &&
          props.location.pathname.split("/").some((i) => isUUID(i))) ||
        (prevUIRef.current.split("/").some((i) => isUUID(i)) &&
          !props.location.pathname.split("/").some((i) => isUUID(i)))
      ) {
      } else {
        props.clearTags();
      }
    }
    if (props.location.pathname !== prevUIRef.current)
      prevUIRef.current = props.location.pathname;
  }, [props.location.pathname]);
  // const [insightsForYouParentName, setInsightsForYouParentName] = useState('');
  //
  // useEffect(() => {
  //     (async () => {
  //         await props.getFilesRoot();
  //         const { user: { isAdmin }, history } = props;
  //         const parentName = props.filesRoot.files.filter(file => file.name === 'Insights for you')[0].parent.name.toLowerCase();
  //         setInsightsForYouParentName(parentName);
  //
  //         if (!isAdmin) {
  //             history.push(`${PATH.BUSINESS}/${createUrl(insightsForYouParentName)}/insights-for-you`);
  //         }
  //     })();
  // }, []);

  useEffect(() => {}, [props.location.pathname]);

  useEffect(() => {
    gtag("event", "PAGE_MOUNTED", {
      category: "PAGE_MOUNTED",
      action: "PAGE_MOUNTED",
      page: props.location.pathname.includes("~")
        ? props.location.pathname.split("~")[0]
        : props.location.pathname,
      label: props.user.id,
    });
  }, [props.location.pathname]);

  const setRedirect = () => {
    const { isAdmin } = props.user;
    const userFromStorage = getUserLocalStorage();
    // const parentName = props.filesRoot.files.filter(file => file.name === 'Insights for you')[0].parent.name.toLowerCase();
    if (!userFromStorage && isAdmin) {
      return redirectTo(ROLES.ADMIN);
    }
    // * INFO: removed redirect
    return `${PATH.BUSINESS}/`;
  };

  return (
    <Suspense fallback={<SkeletonOrigin />}>
      <ScrollToTop />
      <SocketContainer>
        <Switch>
          <Route path="/admin" component={AdminRoutes} />
          <Route path="/business" component={BusinessRoutes} />
          <Redirect to={setRedirect()} />
        </Switch>
      </SocketContainer>
    </Suspense>
  );
};

_Routes.propTypes = {
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  clearTags: PropTypes.func.isRequired,
  filesRoot: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  filesRoot: state.files.filesRoot,
});

const mapDispatchToProps = (dispatch) => ({
  clearTags: () => dispatch(clearTags()),
  getFilesRoot: () => dispatch(getFilesRoot()),
});

export const Routes = compose(
  withRouter,
  ui({
    key: "Sidebar",
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withCookies
)(_Routes);
