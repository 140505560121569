export const ADMIN = "/admin";
export const EXPLORE = "/explore";
export const MY_ZONE = "/my-zone";
export const MY_PROPERTIES = "/my-properties";
export const BUSINESS = "/business";

export const BUSINESS_HASH = "#/business";
export const ADMIN_HASH = "#/admin";

export const PATH = {
  CHOOSE_ROLE: "/choose-role",
  ADMIN,
  ADMIN_PATH: {
    USERS: `${ADMIN}/users`,
    USER: `${ADMIN}/user`,
    GROUPS: `${ADMIN}/groups`,
    AI_MODULE: `${ADMIN}/ai`,
    AI_SMODULE: `${ADMIN}/ai`,
    PEOPLE: `${ADMIN}/people`,
    REAL_ESTATE_NEWS: `${ADMIN}/news/cbre-news`,
    REAL_ESTATE_NEWS2: `${ADMIN}/cbre-news`,
    NEWS: `${ADMIN}/news/news`,
    DASHBOARDS: `${ADMIN}/dashboards`,
    REPORTS: `${ADMIN}/reports`,
    AI_DATA_DRIVEN_SOLUTIONS: `${ADMIN}/ai-&-data-driven-solutions`,
    METHODOLOGY: `${ADMIN}/forecast-analytics/methodology`,
    FORECAST_DATA_LAKE: `${ADMIN}/forecast-analytics/forecast-data-lake`,
    ABOUT_US: `${ADMIN}/about-us`,
    NOTIFICATIONS: `${ADMIN}/notifications`,
    REAL_ESTATE_EVENTS: `${ADMIN}/real-estate-events`,
    MODULES: `${ADMIN}/modules`,
    WORKSPACES: `${ADMIN}/workspaces`,
    TAG_MANAGER: `${ADMIN}/tag-manager`,
    MODULES_MANAGER: `${ADMIN}/modules-manager`,
    APP_MANAGER: `${ADMIN}/app-manager`,
    KIT_DIGITAL: `${ADMIN}/digital-kit`,
  },
  BUSINESS,
  BUSINESS_PATH: {
    HOME: `${BUSINESS}/`,
    PEOPLE: `${BUSINESS}/people`,
    AI_MODULE: `${BUSINESS}/ai`,
    KIT_DIGITAL: `${BUSINESS}/digital-kit`,
    USER: `${BUSINESS}/user`,
    REAL_ESTATE_NEWS: `${BUSINESS}/cbre-news`,
    REPORTS: `${BUSINESS}/reports`,
    REAL_ESTATE_EVENTS: `${BUSINESS}/events`,
    DASHBOARDS: `${BUSINESS}/dashboards`,
    DATA_SCIENCE: `${BUSINESS}/data-science`,
    NEWS: `${BUSINESS}/data-science/news`,
    METHODOLOGY: `${BUSINESS}/data-science/methodology`,
    AI_DATA_DRIVEN_SOLUTIONS: `${BUSINESS}/data-science/ai-&-data-driven-solutions`,
    FORECAST_DATA_LAKE: `${BUSINESS}/data-science/forecast-data-lake`,
    ABOUT_US: `${BUSINESS}/data-science/about-us`,
    WORKSPACES: `${BUSINESS}/workspaces`,
    FAVORITES: `${BUSINESS}/favorites`,
  },
};

export const URL_MODULES_ACCESSES = [
  "cbre-news",
  "reports",
  "user",
  "events",
  "dashboards",
  "data-science",
  "news",
  "methodology",
  "ai-&-data-driven-solutions",
  "forecast-data-lake",
  "about-us",
  "workspaces",
  "favorites",
  "ai",
  "digital-kit",
];
