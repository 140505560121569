import Axios from "axios";
import { useQuery } from "react-query";
import LocalCache from "modules/components/client/v2/helpers";

export const useHasChatbotAccess = (toModule = true) =>
  useQuery({
    queryKey: ["chatbot-access", toModule],
    queryFn: async () => {
      try {
        const access = LocalCache.get(`chatbot-access-${toModule}`);
        if (access) {
          return access;
        }
        try {
          const { data, status } = await Axios.get(
            `files/${
              toModule
                ? "c5dd515f-9b74-454c-816e-f4582c937675"
                : "7969be2a-0337-4ae0-8c93-63e0ffda7061"
            }` // useHasChatbotAccess
          );
          LocalCache.set(
            `chatbot-access-${toModule}`,
            status === 200,
            1000 * 60 * 4
          );
          return status === 200;
        } catch (e) {
          // LocalCache.set(`chatbot-access`, false, 1000 * 60 * 4);
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    keepPreviousData: true,
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
