import React, { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Icon } from "components/Icon";
import { ChatbotIcon } from "./chatbotIcon";
import { gtag } from "ga-gtag";
import { GA_CATEGORIES, GA_EVENTS } from "constants/gaEvents";
import { compose } from "redux";
import ui from "react-redux-ui-tools";
import { useHasChatbotAccess } from "../../queries/chatbot";
const showAt = [
  "cbre-news",
  "reports",
  "users",
  "groups",
  "user",
  "workspaces",
  "ai-&-data-driven-solutions",
  "cbre-news",
  "events",
  "reports",
  "favorites",
  "digital-kit",
  "ai",
];
const _ChatbotBox = ({
  tokenEndpoint = "https://default0159e9d009a04edf96baa3deea363c.28.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crad4_buddy/directline/token?api-version=2022-03-01-preview",
  styleOptions = {},
  myProfile,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: userHasAccess } = useHasChatbotAccess(false);
  const [key, setKey] = useState(0);
  const chatRef = useRef(null);
  const directLineRef = useRef(null);
  const defaultStyleOptionsRef = useRef(null);
  const conversationRef = useRef(null);
  const [helpVisible, setHelpVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasUserSentMessage, setHasUserSentMessage] = useState(false);
  const [chatVisible, setChatVisible] = useState(true);
  const activitySubscriptionRef = useRef(null);
  useEffect(() => {
    if (isOpen && chatRef.current) {
      while (chatRef.current.firstChild) {
        chatRef.current.removeChild(chatRef.current.firstChild);
      }
      initializeCopilot();
    }
  }, [isOpen, key]);

  useEffect(() => {
    let intervalId;

    const showHideLoop = () => {
      if (!isOpen) {
        setHelpVisible(true);
        setIsVisible(false);

        setTimeout(() => {
          setIsVisible(true);
        }, 1000);

        setTimeout(() => {
          setIsVisible(false);

          setTimeout(() => {
            setHelpVisible(false);
          }, 300);
        }, 20000);
      }
    };

    showHideLoop();

    intervalId = setInterval(showHideLoop, 50000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isOpen]);

  const setupActivitySubscription = () => {
    if (!directLineRef.current) return;

    if (activitySubscriptionRef.current) {
      activitySubscriptionRef.current.unsubscribe();
    }

    activitySubscriptionRef.current = directLineRef.current.activity$.subscribe(
      {
        next: (activity) => {
          if (activity.type === "message" && !("role" in activity.from)) {
            setHasUserSentMessage(true);
          }
        },
        error: (error) => {},
      }
    );
  };

  const initializeCopilot = async () => {
    try {
      const apiVersion = new URL(tokenEndpoint).searchParams.get("api-version");
      const [directLineURL, token] = await Promise.all([
        fetch(
          new URL(
            `/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`,
            tokenEndpoint
          )
        )
          .then((response) => {
            if (!response.ok)
              throw new Error("Failed to retrieve regional channel settings.");
            return response.json();
          })
          .then(({ channelUrlsById: { directline } }) => directline),
        fetch(tokenEndpoint)
          .then((response) => {
            if (!response.ok)
              throw new Error("Failed to retrieve Direct Line token.");
            return response.json();
          })
          .then(({ token }) => token),
      ]);

      directLineRef.current = window.WebChat.createDirectLine({
        domain: new URL("v3/directline", directLineURL),
        token,
      });

      conversationRef.current = window.WebChat.createStore();

      defaultStyleOptionsRef.current = {
        backgroundColor: "#FFFFFF",
        bubbleBackground: "#eeeeee",
        bubbleFromUserBackground: STYLES_CONSTANTS.COLORS.PRIMARY_GREEN,
        textContent: "white",
        botAvatarImage: "/assets/img/buddy_icon.png",
        botAvatarInitials: "AI",

        bubbleBorderColor: STYLES_CONSTANTS.COLORS.PRIMARY_GREEN,
        accent: STYLES_CONSTANTS.COLORS.PRIMARY_GREEN,
        ...styleOptions,
      };

      window.WebChat.renderWebChat(
        {
          directLine: directLineRef.current,
          store: conversationRef.current,
          locale: "es",
          styleOptions: defaultStyleOptionsRef.current,
        },
        chatRef.current
      );

      const subscription = directLineRef.current.connectionStatus$.subscribe({
        next(value) {
          if (value === 2) {
            directLineRef.current
              .postActivity({
                localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: "es",
                name: "startConversation",
                type: "event",
              })
              .subscribe();
            subscription.unsubscribe();
          }
        },
      });

      setupActivitySubscription();
    } catch (error) {
      console.error("Failed to initialize copilot:", error);
    }
  };
  useEffect(() => {
    if (hasUserSentMessage) {
      gtag("event", GA_EVENTS.INITIATING_CHATBOT, {
        category: GA_CATEGORIES.CHATBOT,
        action: `${GA_EVENTS.INITIATING_CHATBOT}`,
        label: "chatbot",
        data: myProfile.id,
      });
    }
  }, [hasUserSentMessage]);
  const handleRefresh = async () => {
    try {
      if (activitySubscriptionRef.current) {
        activitySubscriptionRef.current.unsubscribe();
        activitySubscriptionRef.current = null;
      }

      setHasUserSentMessage(false);

      if (directLineRef.current) {
        await directLineRef.current.end();
      }

      directLineRef.current = null;
      conversationRef.current = null;
      defaultStyleOptionsRef.current = null;

      if (chatRef.current) {
        while (chatRef.current.firstChild) {
          chatRef.current.removeChild(chatRef.current.firstChild);
        }
      }

      setChatVisible(false);

      setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
        initializeCopilot();
        setChatVisible(true);
      }, 100);
    } catch (error) {
      console.error("Error refreshing chat:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (activitySubscriptionRef.current) {
        activitySubscriptionRef.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const icon = document.querySelector(ChatButton);
      if (icon && helpVisible && !isOpen) {
        icon.style.animationPlayState = "running";
        setTimeout(() => {
          icon.style.animationPlayState = "paused";
        }, 1000);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);
  if (!userHasAccess) return null;
  if (
    props.location.pathname !== "/business/people" &&
    props.location.pathname !== "/business/people/latest"
  ) {
    return null;
  }
  return (
    <ChatbotContainer>
      {helpVisible && (
        <Fragment>
          <ChatHelpWindow
            className={isVisible ? "visible" : ""}
            onClick={() => {
              setIsVisible(false);
              setHelpVisible(false);
              setIsOpen(true);
            }}
          >
            <p>¡Hola! ¿Necesitas ayuda?</p>
          </ChatHelpWindow>
        </Fragment>
      )}
      {!isOpen && (
        <ChatHelpNotification
          className={isVisible ? "visible" : ""}
        ></ChatHelpNotification>
      )}
      {isOpen && (
        <ChatWindow isOpen={isOpen}>
          <ChatHeader>
            <HeaderTitle>Chatbot People</HeaderTitle>
            <HeaderButtons>
              <HeaderButton
                className="refresh-button"
                onClick={handleRefresh}
                title="Restart conversation"
              >
                <RefreshIcon />
              </HeaderButton>
              <HeaderButton
                className="close-button"
                onClick={() => setIsOpen(false)}
              >
                <CloseIcon />
              </HeaderButton>
            </HeaderButtons>
          </ChatHeader>
          {chatVisible && (
            <ChatboxContainer ref={chatRef} style={{ height: "100%" }} />
          )}
        </ChatWindow>
      )}
      <ChatButton
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIsVisible(false);
          setHelpVisible(false);
        }}
      >
        <ChatIcon>
          <img src={"/assets/img/buddy_icon.png"} alt="Chat bot" />
        </ChatIcon>
      </ChatButton>
    </ChatbotContainer>
  );
};

const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 400;
`;
const SIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
const ChatButton = styled.button`
  width: clamp(60px, 4vw, 80px);
  z-index: 400;
  height: clamp(60px, 4vw, 80px);
  border-radius: 50%;
  box-shadow: -1px 1px 5px rgb(0 0 0 / 48%);
  background-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
  padding: 0;

  &:hover {
    transform: scale(1.1);
  }
`;

const ChatIcon = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: tumble 1s ease infinite;
  animation-play-state: paused;

  @keyframes tumble {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  ${(props) =>
    props.shouldAnimate &&
    `
    animation-play-state: running;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const ChatHelpWindow = styled.div`
  cursor: pointer;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  bottom: 10px;
  right: 88px;

  width: clamp(200px, 12vw, 500px);
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 15px;
  font-family: "Calibre-Medium", sans-serif !important;
  z-index: 1;
  p {
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    @media (min-width: 1200px) {
      font-size: 17px;
    }
    font-family: "Calibre-Medium", sans-serif !important;
    opacity: 0.6;
  }
  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
`;
const ChatHelpNotification = styled.div`
  cursor: pointer;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transition: opacity 0.3s ease, transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 7px;
  right: 9px;
  background: #17e88f;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 64%);
  overflow: hidden;
  padding: 5px;
  font-family: "Calibre-Medium", sans-serif !important;
  z-index: 1;
`;
const ChatWindow = styled.div`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.isOpen ? "0" : "20px")});
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 27vw;
  height: 65vh;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding-top: 50px;
`;

const ChatHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #012a2d;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 1;
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  color: white;
  font-size: 17px;

  @media (min-width: 1200px) {
    font-size: 17px;
  }
  @media (min-width: 1800px) {
    font-size: 22px;
  }
  font-family: "FinancierBold", sans-serif !important;
`;
const ChatboxContainer = styled.div`
  font-size: 13px;
    line-height: 18px;
 
 
    @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 18px;
    .webchat__render-markdown {
    line-height: 20px;
}
         .webchat__bubble p:not(:last-child) {
    margin-bottom: 9px;
}
  }
@media (min-width: 1500px) {
    .kckqkV {
        font-size: 18px;
        line-height: 18px;
    }
}
          @media (min-width: 1600px) {
    font-size: 20px;
    line-height: 25px;
      .webchat__bubble p:not(:last-child) {
    margin-bottom: 15px !important;
}
  }
         @media (min-width: 1800px) {
    font-size: 22px;
    line-height: 35px;
      .webchat__bubble p:not(:last-child) {
    margin-bottom: 20px !important;
}
  }
     @media (min-width: 2200px) {
    font-size: 24px;
    line-height: 26px;
      .webchat__bubble p:not(:last-child) {
    margin-bottom: 20px !important;
}
     .webchat__render-markdown {
    line-height: 28px;
}
  }
@media (min-width: 2700px) {
      
        font-size: 30px;
        line-height: 26px;
        .webchat__render-markdown {
        line-height: 32px !important;
    }
}
    
}
  .webchat__send-icon {
    background-image: url('/assets/svg/ic_send_message.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(40deg);
  background-size: clamp(23px, 1.5vw, 33px);
  }
      .webchat__send-icon path {
    display: none !important;
  }
 div[class*="webchat__upload-button"] {
    display: none !important;
  }
.webchat__bubble p strong {
  font-family: "Calibre-Medium", sans-serif !important;
}

    .webchat__render-markdown.webchat__render-markdown--message-activity > :first-child {
    margin-top: 0px;
}

div[class*="webchat__send-box__main"] {
    min-height: 70px !important;
        padding-left: 15px;
    padding-right: 15px;
}
}
     .webchat__main .webchat__upload-button {
    display: none !important;
  }
  .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content {
      padding: 4px;
    background-color: #eeeeee !important
    border: 0px solid ${STYLES_CONSTANTS.COLORS.MAIN_GREY} !important;
    border-radius: 20px !important;
  }
  /* .webchat--css-oykdc-p2roxj.webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] */

  .webchat__adaptive-card-renderer .ac-pushButton, .webchat__suggested-action {
       background-color: #ffffff !important;
    border: 1px solid #012A2D !important;
    color: #012A2D !important;
    border-radius: 20px !important;
    transition: all 0.1s ease;
    &:hover {

      background-color: #012a2d !important;
      color: white !important;
    }
  }

  .webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] {
    background-color: ${STYLES_CONSTANTS.COLORS.ACCENT_GREEN} !important;

    border-color: ${STYLES_CONSTANTS.COLORS.ACCENT_GREEN} !important;
    color: black;
  }
  .webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] div {
    color: black !important;
  }
  .webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
    background-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN} !important;
    border-radius: 20px !important;
    border: 0px solid ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN} !important;
    min-width: 50px;
    text-align: center;
    padding: 6px;
  }
  .webchat__bubble.webchat__bubble--from-user p {
    color: white;
  }
`;
const HeaderButtons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  &.info-button {
    color: #0078d4;
    width: 24px;
    height: 24px;
    border: 2px solid #0078d4;
    border-radius: 50%;
    font-style: italic;
    font-family: serif;
    font-weight: bold;
  }
  &.refresh-button {
    color: white;
  }
  &.close-button {
    color: white;
    font-size: 20px;
    font-weight: normal;
  }

  &:hover {
    opacity: 0.8;
  }
`;
const Icons = styled.span`
  .material-symbols--close {
    display: inline-block;
    width: 24px;
    height: 24px;
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z'/%3E%3C/svg%3E");
    background-color: currentColor;
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .material-symbols--refresh {
    display: inline-block;
    width: 24px;
    height: 24px;
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 20q-3.35 0-5.675-2.325T4 12t2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V4h2v7h-7V9h4.2q-.8-1.4-2.187-2.2T12 6Q9.5 6 7.75 7.75T6 12t1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325T12 20'/%3E%3C/svg%3E");
    background-color: currentColor;
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;

  &:hover {
    color: #333;
  }
`;
const mapStateToProps = (state) => ({
  myProfile: state.users.currentUser,
  serverInfo: state.user.serverStatus,
});
const mapDispatchToProps = (dispatch) => ({});
const ChatbotBox = compose(
  ui({ key: "Sidebar" }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(_ChatbotBox);
export default ChatbotBox;

const RefreshIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 20q-3.35 0-5.675-2.325T4 12t2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V4h2v7h-7V9h4.2q-.8-1.4-2.187-2.2T12 6Q9.5 6 7.75 7.75T6 12t1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325T12 20"
      />
    </svg>
  );
};
const CloseIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
      />
    </svg>
  );
};
